<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table
            dense
            disable-filtering
            disable-sort
            :footer-props="{
          'items-per-page-options': [5, 10, 20, 30, 40, 50],
          'items-per-page-text': 'Registros por página',
          'page-text': '{0}-{1} de {2}'
        }"
            :headers="headers"
            :items="itens"
            :items-per-page="20"
            :loading="loadingItens"
            :options.sync="options"
            :server-items-length="itensTotal"
            show-expand
            single-expand
        >
          <template v-slot:[`item.origem`]="{ item }">
            <span>{{ item.simulador_frete_rotas[0].simulacao.origem }}</span>
          </template>
          <template v-slot:[`item.destino`]="{ item }">
            <span>{{ item.simulador_frete_rotas[item.simulador_frete_rotas.length-1].simulacao.destino }}</span>
          </template>
          <template v-slot:[`item.paradas`]="{ item }">
            <span>{{ item.simulador_frete_rotas.length }}</span>
          </template>
          <template v-slot:[`item.distancia`]="{ item }">
            <span>{{ $stringFormat.formatNumber(sumQuantidadeTotalKm(item.simulador_frete_rotas), 0, 0) }}</span>
          </template>
          <template v-slot:[`item.quantidade`]="{ item }">
            <span>{{ $stringFormat.formatNumber(sumQuantidadeTotalM2(item.simulador_frete_rotas), 0, 0)  }}</span>
          </template>
          <template v-slot:[`item.valorPorM2`]="{ item }">
            <v-row dense>
              <v-col cols="auto">R$</v-col>
              <v-col class="text-right">
                {{
                  $stringFormat.formatNumber(
                      sumQuantidadeTotalM2(item.simulador_frete_rotas)>0?sumValorTotalM2(item.simulador_frete_rotas)/sumQuantidadeTotalM2(item.simulador_frete_rotas):0, 2, 2
                  )
                }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.valorTotalM2`]="{ item }">
            <v-row dense>
              <v-col cols="auto">R$</v-col>
              <v-col class="text-right">
                {{ $stringFormat.formatNumber(sumValorTotalM2(item.simulador_frete_rotas), 0, 0) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.valorPorKm`]="{ item }">
            <v-row dense>
              <v-col cols="auto">R$</v-col>
              <v-col class="text-right">
                {{
                  $stringFormat.formatNumber(
                      sumQuantidadeTotalKm(item.simulador_frete_rotas)>0?sumValorTotalKm(item.simulador_frete_rotas)/sumQuantidadeTotalKm(item.simulador_frete_rotas):0, 2, 2
                  )
                }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.valorTotalKm`]="{ item }">
            <v-row dense>
              <v-col cols="auto">R$</v-col>
              <v-col class="text-right">
                {{ $stringFormat.formatNumber(sumValorTotalKm(item.simulador_frete_rotas), 0, 0) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ item.created_at | moment('DD/MM/YYYY') }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex ga-2">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      small
                      class="ml-2"
                      @click="$router.push(`/fretes/simulador-fretes/${item.id}`)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-open-in-new
                  </v-icon>
                </template>
                <span>Clonar</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      small
                      class="ml-2"
                      @click="openDialogRemoverSimulacao(item.id)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card flat tile class="transparent my-4">
                <v-card-title>
                  Simulação
                </v-card-title>
                <detalhamento-rotas-table
                    :items="item.simulador_frete_rotas"
                />
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <custom-snackbar ref="snackbar"/>
  <v-dialog v-model="dialogRemoverSimulacao" transition="dialog-bottom-transition" max-width="600">
    <v-card :disabled="dialogRemoverSimulacaoProcessing" :loading="dialogRemoverSimulacaoProcessing">
      <v-toolbar color="info" dark>
        <span class="headline">Apagar Simulação</span>
      </v-toolbar>
      <v-card-text class="text-h5 mt-3">
        Tem certeza que deseja apagar a simulação?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            color="blue darken-1"
            text
            @click="dialogRemoverSimulacao = false"
        >
          Cancelar
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="removeSimulacao"
        >
          Apagar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>

</template>

<script>
import ApiService from '@/core/services/api.service'
import DetalhamentoRotasTable from '@/view/pages/fretes/simulador-fretes/components/DetalhamentoRotasTable'

export default {
  components: {
    DetalhamentoRotasTable,
  },

  props: {
    filtros: {
      type: Object,
    },
    headers: {
      type: Object,
    },
  },

  data: () => ({
    itens: [],
    itensTotal: 0,
    options: {},
    loadingItens: true,
    dialogRemoverSimulacao: false,
    dialogRemoverSimulacaoProcessing: false,
    removerSimulacaoId: null,
  }),

  watch: {
    options: {
      handler () {
        this.pegarSimulacoes(true)
      },
      deep: true,
    },

    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.pegarSimulacoes(true)
    },
  },

  methods: {
    async pegarSimulacoes (reiniciarPaginacao = false) {
      this.loadingItens = true
      this.itens = []

      try {
        const { itemsPerPage, page } = this.options

        const params = {
          itemsPerPage: itemsPerPage,
          page: reiniciarPaginacao || page,
          ...this.filtros,
        }

        const result = (await ApiService.get('fretes/simulador-fretes', params)).data

        this.itens = result.data
        this.itensTotal = result.total
      } catch (e) {
        this.$refs.snackbar.show(
          'Erro',
          'Não foi carregar as Simulações de Frete',
          'danger',
        )
      }

      this.loadingItens = false
    },
    openDialogRemoverSimulacao (id) {
      this.dialogRemoverSimulacao = true
      this.removerSimulacaoId = id
    },
    closeDialogRemoverSimulacao () {
      this.dialogRemoverSimulacao = false
      this.dialogRemoverSimulacaoProcessing = false
      this.removerSimulacaoId = null
    },
    async removeSimulacao () {
      try {
        await ApiService.delete('fretes/simulador-fretes/remover/' + this.removerSimulacaoId)

        this.$refs.snackbar.show(
          'Sucesso',
          'Simulação de Frete excluída com sucesso',
          'success',
        )

        await this.pegarSimulacoes(true)
      } catch (e) {
        this.$refs.snackbar.show(
          'Erro',
          'Não foi possível excluir a Simulação de Frete',
          'danger',
        )
      }

      this.closeDialogRemoverSimulacao()
    },
    sumQuantidadeTotalKm (items) {
      let sum = 0

      items.forEach((item) => {
        sum += item.simulacao.distancia
      })

      return sum
    },

    sumQuantidadeTotalM2 (items) {
      let sum = 0

      items.forEach((item) => {
        sum += item.simulacao.quantidade
      })

      return sum
    },

    sumValorTotalKm (items) {
      let sum = 0

      items.forEach((item) => {
        sum += item.simulacao.valorTotalKm
      })

      return sum
    },

    sumValorTotalM2 (items) {
      let sum = 0

      items.forEach((item) => {
        sum += item.simulacao.valorTotalM2
      })

      return sum
    },
  },
}
</script>
